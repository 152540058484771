.skill-meter {
  width: 60px;
  margin: 20px;
}

.skill-meter h3 {
  color: #fff;
  text-align: center;
  margin-top: 10px;
}

@media (max-width: 768px) {
  .skill-meter {
    width: 50px;
  }

  .skill-meter h3 {
    color: #fff;
    text-align: center;
    margin-top: 10px;
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .skill-meter {
    width: 25px;
  }

  .skill-meter h3 {
    color: #fff;
    text-align: center;
    margin-top: 10px;
    font-size: 7px;
  }
}
