.cards-oa {
  background-color: rgba(255, 255, 255, 0.233);
  margin: 15px auto;
  height: auto;
  width: 90%;
  max-width: 400px;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.6);
  word-wrap: break-word;
}

.cards-oa h3 {
  font-size: 1.2rem;
  margin-bottom: 10px;
  color: white;
  text-align: center;
}

.cards-oa p {
  font-size: 1rem;
  text-align: justify;
  color: white;
  line-height: 1.5;
}

@media (max-width: 768px) {
  .cards-oa {
    max-width: none;
    padding: 10px;
  }

  .cards-oa h3 {
    font-size: 1rem;
  }

  .cards-oa p {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .cards-oa {
    padding: 10px;
  }

  .cards-oa h3 {
    font-size: 0.9rem;
  }

  .cards-oa p {
    font-size: 0.8rem;
  }
}
