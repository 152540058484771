.header-oa {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 50px;
  padding: 20px;
  background: linear-gradient(135deg, #1b263b, #2a3b59);
  position: sticky;
  top: 0;
  z-index: 1000;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.logo {
  font-size: 1.5rem;
  font-weight: bold;
  color: #f1c40f;
}

.menu {
  display: flex;
  gap: 20px;
  align-items: center;
}

.button-oa,
.button-oa-active {
  background: none;
  border: none;
  color: white;
  font-size: 1rem;
  padding: 10px 15px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.button-oa:hover {
  color: #f1c40f;
}

.button-oa-active {
  color: #f1c40f;
  border-bottom: 2px solid #f1c40f;
}

.menu-toggle {
  display: none;
  flex-direction: column;
  cursor: pointer;
  gap: 5px;
}

.menu-toggle .bar {
  width: 25px;
  height: 3px;
  background-color: white;
  transition: all 0.3s ease;
}

.menu.open {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 60px;
  right: 0;
  background-color: #1b263b;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 10px;
  width: 200px;
  z-index: 1000;
}

.menu.open .button-oa {
  text-align: left;
  width: 100%;
  padding: 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

@media (max-width: 768px) {
  .menu {
    display: none;
  }

  .menu-toggle {
    display: flex;
  }
}
