.app-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: linear-gradient(to bottom right, #13213b, #4482c0);
  padding-bottom: 50px;

  .content-container {
    flex: 1;
    overflow-x: auto;
  }
}

@media (max-width: 768px) {
  .app-container {
    padding: 0 1px;

    .content-container {
      display: flex;
      justify-content: center;
    }
  }
}
