.container {
  display: flex;
  justify-content: center;
  align-items: center;

  h3 {
    text-align: center;
    margin: 0;
  }

  p {
    text-align: left;
  }

  .cards-oa {
    height: 270px;
  }
}
