.download-section {
  text-align: center;
  margin: 50px 0;
}

.download-button {
  padding: 10px 20px;
  font-size: 18px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
}

.download-button:hover {
  background: #0056b3;
}

.modal-content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #222;
  color: #fff;
  padding: 20px;
  border-radius: 12px;
  text-align: center;
  z-index: 1000;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
}

.progress-bar-container {
  width: 100%;
  height: 10px;
  background-color: #555;
  border-radius: 5px;
  overflow: hidden;
  margin: 20px 0;
}

.progress-bar {
  height: 100%;
  background-color: #007bff;
  transition: width 0.3s ease;
}

.download-complete {
  color: #00ff00;
}

.close-button {
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 8px;
  background: #ff4136;
  color: #fff;
  border: none;
  cursor: pointer;
  margin-top: 10px;
}

.close-button:hover {
  background: #e23631;
}
