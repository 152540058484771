.footer-oa {
  background-color: #1b263b;
  color: white;
  padding: 15px 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  align-items: center;
  justify-items: center;
  font-size: 0.9rem;
}

.contact-card {
  flex: 1;
  min-width: 250px;
}

.contact-card h3 {
  margin-bottom: 20px;
  font-size: 1.5rem;
  color: #f1c40f;
}

.contact-card p {
  margin: 5px 0;
}

.contact-card a {
  color: #f1c40f;
  text-decoration: none;
}

.contact-card a:hover {
  text-decoration: underline;
}

.social-card {
  flex: 1;
  min-width: 250px;
}

.social-card h3 {
  margin-bottom: 20px;
  font-size: 1.5rem;
  color: #f1c40f;
}

.social-icons {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.social-link {
  color: white;
  transition: transform 0.2s, color 0.2s;
}

.social-link:hover {
  color: #f1c40f;
  transform: scale(1.1);
}

/* Responsivitate */
@media (max-width: 768px) {
  .footer-oa {
    grid-template-columns: 1fr;
    text-align: center;
  }

  .contact-card,
  .social-card {
    text-align: center;
  }
}

@media (max-width: 480px) {
  .footer-oa {
    padding: 20px;
  }

  .contact-card h3,
  .social-card h3 {
    font-size: 1.2rem;
  }

  .social-icons {
    gap: 10px;
  }
}
