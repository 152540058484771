.tooltip {
  position: fixed;
  background: linear-gradient(145deg, #001f3f, #007bff);
  padding: 15px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  color: white;
  width: 200px;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s;
  pointer-events: none;
}

.tooltip h2 {
  margin-bottom: 10px;
  font-size: 18px;
  color: #d4af37;
}

.tooltip p {
  font-size: 14px;
  color: #e0e0e0;
}

.tooltip {
  opacity: 1;
  visibility: visible;
}

.hexagon-grid {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
  flex-wrap: wrap;
  padding: 20px;
  position: relative;
}

.hexagon {
  width: 150px;
  height: 165px;
  background: linear-gradient(36deg, #007bff, #001f3f);
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  gap: 10px;
  transition: transform 0.3s, box-shadow 0.6s;
}

.hexagon:hover {
  transform: scale(1.1);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.4);
}

.hexagon-content {
  color: white;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
}

@media (max-width: 768px) {
  .tooltip {
    width: 180px;
    padding: 10px;
  }

  .tooltip h2 {
    font-size: 16px;
  }

  .tooltip p {
    font-size: 12px;
  }

  .hexagon {
    width: 120px;
    height: 140px;
  }

  .hexagon-content {
    font-size: 12px;
  }

  .hexagon-grid {
    gap: 30px;
  }
}

/* Telefoane */
@media (max-width: 480px) {
  .tooltip {
    width: 150px;
    padding: 8px;
  }

  .tooltip h2 {
    font-size: 14px;
  }

  .tooltip p {
    font-size: 10px;
  }

  .hexagon {
    width: 100px;
    height: 115px;
  }

  .hexagon-content {
    font-size: 10px;
  }

  .hexagon-grid {
    gap: 20px;
    padding: 10px;
  }
}
