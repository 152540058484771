.container {
  display: flex;
  justify-content: center;
  align-items: center;

  h3 {
    text-align: center;
    margin: 0;
  }

  p {
    text-align: justify;
  }
}

@media (max-width: 768px) {
  .container {
    padding: 10px;
  }

  .container h3 {
    font-size: 1.2rem;
  }

  .container p {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .container {
    flex-direction: column;
    padding: 5px;
    gap: 10px;
  }

  .container h3 {
    font-size: 1.2rem;
  }

  .container p {
    font-size: 1rem;
  }
}
